.detailsWrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.icons {
  cursor: pointer;
}
.icons:hover {
  opacity: 0.8;
}

.ant-layout-sider-children {
  background: radial-gradient(
    circle,
    rgba(136, 206, 191, 1) 35%,
    rgba(136, 206, 191, 1) 100%
  ) !important;
}

.ant-layout-sider-trigger {
  background: radial-gradient(
    circle,
    rgba(136, 206, 191, 1) 35%,
    rgba(136, 206, 191, 1) 100%
  ) !important;
}

.ant-layout-sider-zero-width-trigger {
  background: radial-gradient(
    circle,
    rgba(136, 206, 191, 1) 35%,
    rgba(136, 206, 191, 1) 100%
  ) !important;
}

.ant-layout-sider {
  background: radial-gradient(
    circle,
    rgba(136, 206, 191, 1) 35%,
    rgba(136, 206, 191, 1) 100%
  ) !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  color: #ffffffc9;
}

li.ant-menu-item {
  color: #ffffffc9;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff78 !important;
  color: #ffffffff !important;
}

.ant-layout-content {
  background: #f6fafd !important;
}

.ant-menu {
  background: none !important;
}

.anticon-check {
  color: #1da57a !important;
}
.anticon-close {
  color: red !important;
}
.ant-menu-inline {
  border-right: 1px solid
    radial-gradient(circle, rgba(17, 17, 17, 1) 35%, rgba(34, 34, 34, 1) 100%) !important;
}

span.ant-menu-title-content {
  font-size: 13.3px;
}

.ant-breadcrumb-link {
  display: inline-flex;
}

/* search button */
button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-btn-rtl.ant-input-search-button {
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0px !important;
}
.ant-form-item-explain-error {
  margin-bottom: 7px;
  margin-top: 7px;
}

/* //////////////// */
.messages::-webkit-scrollbar {
  display: none;
}
.ant-form-item-explain-error {
  margin-bottom: -2px;
  margin-top: 9px;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* min-height: 800px !important; */
  background: #f0f2f5;
}

.ant-form-item-with-help {
  margin-bottom: 24px !important;
  transition: none;
}
